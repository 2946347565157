import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input, { default as GenericInput } from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import TextEditor from '../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { createTransactionalModel, isDirty } from '../../../../../../@Util/TransactionalModelV2/index';
import { EntityTypeApi } from '../../../../../../@Api/Api/EntityTypeApi';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { default as EntityInput } from '../../../../Entity/Input/Input';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import useMetadataSettingFlag from '../../../../../../@Api/Metadata/useMetadataSettingFlag';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { EntityExpansionBuilder } from '../../../../Entity/Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import hasPack from '../../../../../../@Api/Pack/hasPack';
import { Pack } from '../../../../../../@Api/Pack/Pack';
import ResourcePlannerColorEditor from '../ResourcePlannerColorEditor/ResourcePlannerColorEditor';

export interface GeneralEditorProps
{
    entityType: EntityType;
    onAddField: (fieldPath: EntityFieldPath) => void;
}

const GeneralEditor: React.FC<GeneralEditorProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const hasAccessToPostCodeApi = hasPack(Pack.PostalCode);

        const transactionalType =
            useMemo(
                () =>
                    createTransactionalModel(props.entityType),
                [
                    props.entityType
                ]);

        const [ hasProducts, setHasProducts ] = useMetadataSettingFlag(props.entityType, Setting.Metadata.HasProducts);
        const [ hasInvolved, setHasInvolved ] = useMetadataSettingFlag(props.entityType, Setting.Metadata.HasInvolved);
        const [ showFinancialWidgetsInProject, setShowFinancialWidgetsInProject ] = useSetting<boolean>(SettingSource.Organization, Setting.Project.ShowFinancialWidgets, true);
        const [ formatAddressAsNEN5825, setFormatAddressAsNEN5825 ] = useSetting<boolean>(SettingSource.Organization, Setting.Address.UseNEN5825Format, false);

        useEffect(
            () =>
            {
                new EntityExpansionBuilder(
                    transactionalType.entity.entityType,
                    [
                        transactionalType.entity
                    ],
                    [
                        EntityPath.fromEntity(transactionalType.entity)
                            .joinTo(
                                types.Pack.RelationshipDefinition.Entities,
                                true)
                    ])
                    .expand()
            },
            [
                transactionalType,
                types
            ]);

        const save =
            useCallback(
                () =>
                    new EntityTypeApi()
                        .commit(transactionalType),
                [
                    transactionalType
                ]);

        const isValid =
            useComputed(
                () =>
                    transactionalType.entity.hasValueForField(types.EntityType.Field.LocalizedSingularName)
                        && transactionalType.entity.hasValueForField(types.EntityType.Field.LocalizedPluralName),
                [
                    transactionalType
                ]);

        const hasResourcePlannerColor =
            useComputed(
                () =>
                    props.entityType.isA(types.Activity.Event.Type) ||
                    props.entityType.isA(types.Activity.Appointment.Type) ||
                    props.entityType.isA(types.Activity.WorkOrder.Type) ||
                    props.entityType.isA(types.Activity.Task.Type) ||
                    props.entityType.isA(types.TimeRegistration.Type),
            [
                    types,
                    props.entityType
                ]
            );

        const hasResourceSettings =
            useComputed(
                () =>
                        hasResourcePlannerColor ||
                        props.entityType.isA(types.Activity.Type),
                [
                    types,
                    hasResourcePlannerColor,
                    props.entityType
                ]
            );

        const canChangePack =
            useMemo(
                () =>
                    // PI202401145: forms should belong to environment pack, as they are public
                    // and the submit of the form should be in the organization context
                    !props.entityType.isA(types.Form.Type)
                    && currentUserStore.rightProfile.ownedPacks.length > 1,
                [
                    types,
                    props.entityType,
                    currentUserStore.rightProfile
                ]
            );

        return <>
            <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <InputGroup>
                            <EntityInput
                                entity={transactionalType.entity}
                                field={types.EntityType.Field.LocalizedSingularName}
                                doAutocommit={false}
                            />
                            <EntityInput
                                entity={transactionalType.entity}
                                field={types.EntityType.Field.LocalizedPluralName}
                                doAutocommit={false}
                            />
                            <GenericInput
                                label={
                                    <LocalizedText
                                        code="Generic.CodeInApi"
                                        value="Typenaam in API"
                                    />
                                }
                                labelPosition="left"
                            >
                                <TextEditor
                                    value={props.entityType.code}
                                    disabled
                                />
                            </GenericInput>
                            {
                                canChangePack &&
                                    <EntityInput
                                        labelPosition="left"
                                        entity={transactionalType.entity}
                                        field={types.Pack.RelationshipDefinition.Entities}
                                        parent
                                        doAutocommit={false}
                                    />
                            }
                        </InputGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Save"
                                        value="Opslaan"
                                    />
                                }
                                onClick={save}
                                disabled={!isDirty(transactionalType) || !isValid}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                    {
                        hasResourceSettings &&
                            <ViewGroupItem>
                                <CardHeader>
                                    <LocalizedText
                                        code="Generic.ResourceSettings"
                                        value="${resource} instellingen"
                                        resource={props.entityType.getName()}
                                    />
                                </CardHeader>
                            </ViewGroupItem>
                    }
                    {
                        props.entityType.isA(types.Activity.Type) &&
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        labelPosition="left"
                                        label={
                                            <LocalizedText
                                                code="Configuration.HasProductAdministration"
                                                value="Heeft productenbeheer"
                                            />
                                        }
                                    >
                                        <Switch
                                            onToggle={setHasProducts}
                                            checked={hasProducts}
                                        />
                                    </Input>
                                    <Input
                                        labelPosition="left"
                                        label={
                                            <LocalizedText
                                                code="Configuration.HasInvolved"
                                                value="Heeft betrokkenen"
                                            />
                                        }
                                    >
                                        <Switch
                                            onToggle={setHasInvolved}
                                            checked={hasInvolved}
                                        />
                                    </Input>
                                    {
                                        props.entityType.isA(types.Activity.Project.Type) &&
                                            <Input
                                                labelPosition="left"
                                                label={
                                                    <LocalizedText
                                                        code="Configuration.ShowFinancialWidgetsInProject"
                                                        value="Financiële widgets tonen"
                                                    />
                                                }
                                            >
                                                <Switch
                                                    onToggle={setShowFinancialWidgetsInProject}
                                                    checked={showFinancialWidgetsInProject}
                                                />
                                            </Input>
                                    }
                                </InputGroup>
                            </ViewGroupItem>
                    }
                    {
                        hasResourcePlannerColor &&
                        <ViewGroupItem>
                            <ResourcePlannerColorEditor
                                entityType={props.entityType}
                            />
                        </ViewGroupItem>
                    }
                    {
                        props.entityType.isA(types.Address.Type) && hasAccessToPostCodeApi &&
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        labelPosition="left"
                                        label={
                                            <LocalizedText
                                                code="Configuration.Address.UseNenAddress"
                                                value="Gebruik NEN5825 formaat"
                                            />
                                        }
                                    >
                                        <Switch
                                            onToggle={setFormatAddressAsNEN5825}
                                            checked={formatAddressAsNEN5825}
                                        />
                                    </Input>
                                </InputGroup>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </CardInset>
        </>;
    };

export default observer(GeneralEditor);
